export const environment = {
  production: false,
  apiProjectUrl: 'https://a314-projects-qa.avwaveinteractive.com/projects',
  apiUrlAdmin: 'https://a314-admin-users-qa.avwaveinteractive.com/admin-users',
  apiGoogleStorage: 'https://a314-google-storage-qa.avwaveinteractive.com/storage',
  urlStorage: 'https://gamma-avwave-media.storage.googleapis.com/',
  testUser: {
    // tslint:disable-line
    token: 'cualquiercosa',
    email: 'user@user.user',
  },
};
